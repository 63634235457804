import { useMemo, useState } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useGetVisitasQuery, useGetVisitasIndicatorsQuery } from '../../../services/visitasApi';
import { Visita } from '../consts/visitasTypes';
import { useRoles } from '../../../hooks/useRoles';

const useVisitas = ({ filters }: any) => {
    const dispatch = useAppDispatch();
    const { isPreventor } = useRoles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [selected, setSelected] = useState<Visita | null>(null);
    const [openCard, setOpenCard] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerAction, setDrawerAction] = useState<'Detalle' | 'Cancelar' | 'Rechazar' | null>(
        null
    );
    const [selectedVisita, setSelectedVisita] = useState<Visita | null>(null);

    const filterIsPreventor = useMemo(() => {
        return isPreventor ? { ...filters, idEstado: 1 } : filters;
    }, [filters, isPreventor]);

    const {
        data: visitasData,
        isLoading,
        refetch: refetchVisitas,
        isFetching
    } = useGetVisitasQuery(filterIsPreventor);

    const { data: indicatorsData, isFetching: isFetchingIndicators } = useGetVisitasIndicatorsQuery(
        {}
    );

    const handleClickAction = (event: any, item: Visita) => {
        setSelected(item);
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleSelectRow = (item: Visita) => {
        setSelected(item);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleCloseCard = () => {
        setOpenCard(false);
        setSelected(null);
    };

    const handleShowVisitaData = () => {
        setOpenCard(true);
        setMenuOpen(false);
    };

    const handleOpenDrawer = (action: 'Detalle' | 'Cancelar' | 'Rechazar', visita: Visita) => {
        setDrawerAction(action);
        setSelectedVisita(visita);
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setDrawerAction(null);
        setSelectedVisita(null);
    };

    return {
        selected,
        visitasData,
        indicatorsData,
        isFetchingIndicators,
        openCard,
        anchorEl,
        menuOpen,
        isLoading,
        isFetching,
        handleClickAction,
        handleSelectRow,
        handleCloseMenu,
        handleCloseCard,
        handleShowVisitaData,
        refetchVisitas,
        drawerOpen,
        drawerAction,
        handleOpenDrawer,
        handleCloseDrawer,
        selectedVisita,
        setDrawerOpen
    };
};

export default useVisitas;
