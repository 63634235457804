import _ from 'lodash';
import { Navigate, Route, Routes } from 'react-router-dom';
import useUserAccess from '../../../hooks/useUserAccess';
import { PATHS } from './paths';
import { useRoles } from '../../../hooks/useRoles';
import { getAccessValues } from './access';
import { lazy, Suspense } from 'react';
import NotificacionesIndex from '../../../pages/Notificaciones/NotificacionesIndex';
import NotificacionDataForm from '../../../pages/Notificaciones/components/NotificacionDataForm';
import RARView from '../../../pages/Rar/components/RARView/RARView';
import NominaAsignacion from '../../../pages/Empresas/NominaAsignacion';
import PresentacionesModuleIndex from '../../../pages/Presentaciones';
import VisitasIndex from '../../../pages/Visitas/VisitasIndex';
import CreateVisita from '../../../pages/Visitas/CreateVisitas/createVisita';

const ComunicacionesEventosIndex = lazy(
    () => import('../../../pages/ComunicacionesEventos/ComunicacionesEventosIndex')
);
const ComunicacionesEvtDataForm = lazy(
    () => import('../../../pages/ComunicacionesEventos/components/ComunicacionDataForm')
);
const ContenidoDisposiciones = lazy(
    () => import('../../../pages/Contenido/components/ContenidoDisposiciones')
);
const ContenidoGraficos = lazy(
    () => import('../../../pages/Contenido/components/ContenidoGraficos')
);
const ContenidoIndex = lazy(() => import('../../../pages/Contenido/ContenidoIndex'));
const ContenidoNoticias = lazy(
    () => import('../../../pages/Contenido/components/ContenidoNoticias')
);
const ContenidoNovedades = lazy(
    () => import('../../../pages/Contenido/components/ContenidoNovedades')
);
const ContenidoVideos = lazy(() => import('../../../pages/Contenido/components/ContenidoVideos'));
const EmpresasIndex = lazy(() => import('../../../pages/Empresas/EmpresasIndex'));
const UpdateRole = lazy(() => import('../../../pages/AdminRoles/components/RoleDataForm'));
const UsersIndex = lazy(() => import('../../../pages/Usuarios/UsersIndex'));
const UsersSave = lazy(() => import('../../../pages/Usuarios/UsersSave'));
const EstablecimientosView = lazy(() => import('../../../pages/Empresas/EmpresasAsignacion'));
const EstablishmentSave = lazy(() => import('../../../pages/Empresas/EstablishmentSave'));
const EstablishmentSaveWithSRT = lazy(() => import('../../../pages/Empresas/EstablishmentSaveWithSRT'));
const PresentacionesForm = lazy(() => import('../../../pages/RGRL/PresentacionesForm'));
const Presentaciones = lazy(() => import('../../../pages/Presentaciones/Presentaciones'));
const NominaDetail = lazy(() => import('../../../pages/Rar/components/NominaDetail/NominaDetail'));
const RGRLEditIndex = lazy(() => import('../../../pages/RGRL/RGRLEdit/RGRLEditIndex'));
const RGRLViewIndex = lazy(() => import('../../../pages/RGRL/RGRLView/RGRLView'));
const UserShow = lazy(() => import('../../../pages/Usuarios/UserShow'));
const PresentacionesIndex = lazy(() => import('../../../pages/Presentaciones/PresentacionesIndex'));
const ImportNomina = lazy(() => import('../../../pages/Rar/components/importNomina/ImportNomina'));
const Organisms = lazy(() => import('../../../pages/Organismos'));
const OrganismSave = lazy(() => import('../../../pages/Organismos/components/AddOrganism'));
const Employees = lazy(() => import('../../../pages/Empleados'));
const HelpCenter = lazy(() => import('../../../pages/CentroDeAyuda'));
const WorkStations = lazy(() => import('../../../pages/PuestosDeTrabajo'));
const WorkStationSave = lazy(() => import('../../../pages/PuestosDeTrabajo/WorkPositionSave'));
const RGRLIndex = lazy(() => import('../../../pages/GeneracionArchivosSRT/RGRL/pages/Index'));
const RarUploadV2 = lazy(() => import('../../../pages/RARV2/pages/RarUploadV2'));
const SendRar = lazy(() => import('../../../pages/RARV2/pages/SendRar'));
const Home = lazy(() => import('../../../pages/Home/index'));

function AdminLayout() {
    const access = useUserAccess();

    const FEATURES = getAccessValues(access);

    const { isAdminBO, isSupervisorSIMP, isOperatorSIMP, isViewerBO, isRRHHSIMP, isPreventor } =
        useRoles();
    const checkRoutePrefix = () => {
        if (isAdminBO || isViewerBO || isPreventor) {
            return 'backoffice';
        }

        if (isSupervisorSIMP || isOperatorSIMP || isRRHHSIMP) {
            return 'simp';
        }

        return 'prefixError';
    };

    const routePrefix: string = checkRoutePrefix();

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {/* {<Route path={`${routePrefix}/home`} element={<EmpresasIndex page={'home'} />} />} */}
                {<Route path={`${routePrefix}/home`} element={<Home />} />}

                {FEATURES.GESTION_USUARIOS.hasAccess && FEATURES.GESTION_USUARIOS.url && (
                    <Route
                        path={`${routePrefix}${FEATURES.GESTION_USUARIOS.url}`}
                        element={<UsersIndex />}
                    />
                )}

                {FEATURES.ALTA_USUARIO.hasAccess && FEATURES.ALTA_USUARIO.url && (
                    <Route path={FEATURES.ALTA_USUARIO.url} element={<UsersSave />} />
                )}

                {FEATURES.ALTA_USUARIO.hasAccess && (
                    <Route path={`${routePrefix}/usuarios/crear-usuario`} element={<UsersSave />} />
                )}

                {FEATURES.GESTION_ESTABLECIMIENTOS.hasAccess &&
                    FEATURES.GESTION_ESTABLECIMIENTOS.url && (
                        <Route
                            path={`${routePrefix}${FEATURES.GESTION_USUARIOS.url}`}
                            element={<UsersIndex />}
                        />
                    )}

                {FEATURES.ALTA_USUARIO.hasAccess && FEATURES.ALTA_USUARIO.url && (
                    <Route path={FEATURES.ALTA_USUARIO.url} element={<UsersSave />} />
                )}

                {FEATURES.ALTA_USUARIO.hasAccess && (
                    <Route path={`${routePrefix}/usuarios/crear-usuario`} element={<UsersSave />} />
                )}

                {FEATURES.GESTION_ESTABLECIMIENTOS.hasAccess &&
                    FEATURES.GESTION_ESTABLECIMIENTOS.url && (
                        <Route
                            path={`${routePrefix}${FEATURES.GESTION_USUARIOS.url}`}
                            element={<UsersIndex />}
                        />
                    )}
                {!!access?.[1]?.[1] && (
                    <Route
                        path="simp/usuarios/asignar-establecimiento"
                        element={<EstablecimientosView />}
                    />
                )}
                {!!access?.[1]?.[1] && (
                    <Route
                        path="backoffice/usuarios/asignar-establecimiento"
                        element={<EstablecimientosView />}
                    />
                )}

                {FEATURES.ALTA_USUARIO.hasAccess && FEATURES.ALTA_USUARIO.url && (
                    <Route path={FEATURES.ALTA_USUARIO.url} element={<UsersSave />} />
                )}

                {FEATURES.ALTA_USUARIO.hasAccess && (
                    <Route path={`${routePrefix}/usuario/crear-usuario`} element={<UsersSave />} />
                )}

                {FEATURES.GESTION_ESTABLECIMIENTOS.hasAccess &&
                    FEATURES.GESTION_ESTABLECIMIENTOS.url && (
                        <Route
                            path={`${routePrefix}${FEATURES.GESTION_ESTABLECIMIENTOS.url}`}
                            element={<EmpresasIndex page="establecimientos" />}
                        />
                    )}
                {!!access?.[1]?.[1] && (
                    <Route
                        path="simp/usuarios/asignar-establecimiento"
                        element={<EstablecimientosView />}
                    />
                )}
                {!!access?.[1]?.[1] && (
                    <Route
                        path="backoffice/usuarios/asignar-establecimiento"
                        element={<EstablecimientosView />}
                    />
                )}

                {/* THIS ACCESS SHOULD BE UPDATED, ITS SHOULD CHECK ALTA_ESTABLECIMIENTOS */}
                {FEATURES.GESTION_ESTABLECIMIENTOS.hasAccess && isAdminBO && isViewerBO && (
                    <Route path={PATHS.BO.ESTABLISHMENTS_CREATE} element={<EstablishmentSave />} />
                )}

                {FEATURES.ALTA_ESTABLECIMIENTOS.hasAccess && isAdminBO && (
                    <Route path={PATHS.BO.ESTABLISHMENTS_CREATE} element={<EstablishmentSave />} />
                )}

                {FEATURES.ALTA_ESTABLECIMIENTOS.hasAccess &&
                    (isOperatorSIMP || isSupervisorSIMP) && (
                        <Route
                            path={PATHS.SIMP.ESTABLISHMENTS_CREATE}
                            element={<EstablishmentSave />}
                        />
                    )}
                <Route
                    path={`${routePrefix}/establecimientos/alta-de-establecimiento-con-srt`}
                    element={<EstablishmentSaveWithSRT />}
                />

                {FEATURES.GESTION_PRESENTACIONES.hasAccess && (
                    <Route
                        path={PATHS.BO.PRESENTACIONES_MODULE}
                        element={<PresentacionesModuleIndex />}
                    />
                )}

                {FEATURES.GESTION_NOMINA_EMPLEADOS.hasAccess && (
                    <Route path={PATHS.RAR_DETALLE_SIMP} element={<NominaDetail />} />
                )}

                {FEATURES.GESTION_NOMINA_EMPLEADOS.hasAccess && (
                    <Route path={PATHS.RAR_DETALLE_EDITAR_SIMP} element={<NominaDetail />} />
                )}

                {FEATURES.GESTION_NOMINA_EMPLEADOS.hasAccess && (
                    <Route
                        path={PATHS.RAR_DETALLE_EDITAR_SIMP_ESTABLECIMIENTO}
                        element={<RarUploadV2 />}
                    />
                )}

                {FEATURES.GESTION_NOMINA_EMPLEADOS.hasAccess && (
                    <Route path={PATHS.RAR_DETALLE_VISUALIZAR_SIMP} element={<NominaDetail />} />
                )}

                <Route path="usuarios/perfil" element={<UserShow />} />

                <Route path="notificaciones" element={<NotificacionesIndex />} />
                <Route path="notificaciones/:id" element={<NotificacionDataForm />} />

                {!!access?.[5]?.[81] && (
                    <>
                        <Route
                            path="notificaciones-eventos"
                            element={<ComunicacionesEventosIndex />}
                        />
                        <Route
                            path="notificaciones-eventos/:id"
                            element={<NotificacionDataForm />}
                        />
                    </>
                )}

                {!!access?.[1]?.[1] && (
                    <Route
                        path="simp/usuario/asignar-establecimiento"
                        element={<EstablecimientosView />}
                    />
                )}
                {!!access?.[1]?.[1] && (
                    <Route
                        path="backoffice/usuario/asignar-establecimiento"
                        element={<EstablecimientosView />}
                    />
                )}

                {FEATURES.GESTION_PRESENTACIONES.hasAccess && (
                    <Route
                        path={`${routePrefix}/establecimiento/presentaciones`}
                        element={<PresentacionesIndex />}
                    >
                        <Route index element={<Presentaciones />} />
                        {!!access?.[4]?.[14] && (
                            <Route path="crear-RGRL" element={<PresentacionesForm />} />
                        )}

                        {!!access?.[4] && (isSupervisorSIMP || isOperatorSIMP) && (
                            <Route path="crear" element={<ImportNomina />} />
                        )}

                        {!!access?.[4] && (isSupervisorSIMP || isOperatorSIMP) && (
                            <Route path="rar" element={<RarUploadV2 />} />
                        )}
                        {!!access?.[4] && (isSupervisorSIMP || isOperatorSIMP) && (
                            <Route path="enviar-rar" element={<SendRar />} />
                        )}
                        {FEATURES.EDICION_PRESENTACION.hasAccess && (
                            <Route path="editar-rar" element={<NominaDetail />} />
                        )}
                        {FEATURES.EDICION_PRESENTACION.hasAccess ||
                            (FEATURES.EDITAR_PRESENTACION_BO && (
                                <Route path="editar" element={<RGRLEditIndex />} />
                            ))}
                        <Route path="visualizar" element={<RGRLViewIndex />} />

                        <Route
                            path={PATHS.RAR_DETALLE_VISUALIZAR_BACKOFFICE}
                            element={<RARView />}
                        />
                    </Route>
                )}

                {FEATURES.GESTION_PRESENTACIONES.hasAccess && (
                    <Route path={`${routePrefix}/presentaciones`} element={<PresentacionesIndex />}>
                        <Route path="visualizar" element={<RGRLViewIndex />} />

                        <Route
                            path={PATHS.RAR_DETALLE_VISUALIZAR_BACKOFFICE}
                            element={<RARView />}
                        />
                    </Route>
                )}

                {!!access?.[4] && (isSupervisorSIMP || isOperatorSIMP) && (
                    <Route path={PATHS.RAR_DETALLE_SIMP} element={<NominaDetail />} />
                )}

                {!!access?.[4] && (isSupervisorSIMP || isOperatorSIMP) && (
                    <Route path={PATHS.RAR_DETALLE_EDITAR_SIMP} element={<NominaDetail />} />
                )}

                {!!access?.[4] && (isSupervisorSIMP || isOperatorSIMP) && (
                    <Route path={PATHS.SIMP.NOMINA_ASIGNACION} element={<NominaAsignacion />} />
                )}

                {isRRHHSIMP && (
                    <Route path={PATHS.SIMP.NOMINA_ASIGNACION} element={<NominaAsignacion />} />
                )}

                {FEATURES.ASIGNACION_NOMINA_ESTABLECIMIENTO.hasAccess && (
                    <Route path={PATHS.BO.NOMINA_ASIGNACION} element={<NominaAsignacion />} />
                )}

                <Route path="usuarios/perfil" element={<UserShow />} />

                {!!access?.[5]?.[81] && (
                    <>
                        <Route
                            path="comunicaciones-eventos"
                            element={<ComunicacionesEventosIndex />}
                        />
                        <Route
                            path="comunicaciones-eventos/:id"
                            element={<ComunicacionesEvtDataForm />}
                        />
                    </>
                )}

                {!!access?.[5]?.[55] && (
                    <Route path="gestor-contenidos" element={<ContenidoIndex />} />
                )}

                {!!access?.[5]?.[55] && (
                    <Route path="gestor-contenidos/graficos" element={<ContenidoGraficos />} />
                )}

                {!!access?.[5]?.[55] && (
                    <Route path="gestor-contenidos/videos" element={<ContenidoVideos />} />
                )}

                {!!access?.[5]?.[55] && (
                    <Route path="gestor-contenidos/noticias" element={<ContenidoNoticias />} />
                )}

                {!!access?.[5]?.[55] && (
                    <Route path="gestor-contenidos/novedades" element={<ContenidoNovedades />} />
                )}

                {!!access?.[5]?.[55] && (
                    <Route
                        path="gestor-contenidos/disposiciones"
                        element={<ContenidoDisposiciones />}
                    />
                )}

                <Route path="administracion/:id" element={<UpdateRole />} />
                {FEATURES.GESTION_ORGANISMOS.hasAccess && (
                    <Route path={`${routePrefix}/organismos`} element={<Organisms />} />
                )}

                {FEATURES.ALTA_ORGANISMOS.hasAccess && (
                    <Route
                        path={`${routePrefix}/organismos/nuevo-organismo`}
                        element={<OrganismSave />}
                    />
                )}

                {FEATURES.ALTA_ORGANISMOS.hasAccess && (
                    <Route
                        path={`${routePrefix}/organismos/nuevo-organismo`}
                        element={<OrganismSave />}
                    />
                )}

                {FEATURES.GESTION_NOMINA_EMPLEADOS.hasAccess && (
                    <Route path={`${routePrefix}/empleados`} element={<Employees />} />
                )}

                {FEATURES.GESTION_NOMINA_EMPLEADOS.hasAccess && (
                    <Route path={`${routePrefix}/nomina-de-empleados`} element={<Employees />} />
                )}

                <Route path={`${routePrefix}/centro-de-ayuda`} element={<HelpCenter />} />

                {FEATURES.GESTION_PUESTOS_DE_TRABAJO.hasAccess && (
                    <Route path={`${routePrefix}/puestos`} element={<WorkStations />} />
                )}

                {FEATURES.ALTA_PUESTO_DE_TRABAJO.hasAccess && (
                    <Route
                        path={`${routePrefix}/puestos/alta-de-puesto-de-trabajo`}
                        element={<WorkStationSave />}
                    />
                )}

                {FEATURES.GESTION_DE_PRESENTACIONES_VINCULACION_RGRL && (
                    <Route
                        path={`${routePrefix}/gestion-de-archivos-para-la-SRT`}
                        element={<RGRLIndex />}
                    />
                )}

                <Route path={`${routePrefix}/centro-de-ayuda`} element={<HelpCenter />} />

                {FEATURES.GESTION_DE_VISITAS && (
                    <Route path={`${routePrefix}/visitas`} element={<VisitasIndex />} />
                )}

                {FEATURES.ALTA_DE_VISITAS && (
                    <Route path={`${routePrefix}/visitas/crear`} element={<CreateVisita />} />
                )}

                <Route path="*" element={<p>Ups! Página no encontrada</p>} />
                <Route path="/" element={<Navigate to={`${routePrefix}/home`} replace />} />
            </Routes>
        </Suspense>
    );
}

export default AdminLayout;
