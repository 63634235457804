import { Chip } from '@mui/material';
import { visitaEstadoChipMap, VisitaEstado } from '../consts/visitasStatus';

interface VisitaEstadoChipProps {
    estadoId: number;
}

const VisitaEstadoChip = ({ estadoId }: VisitaEstadoChipProps) => {
    const chip = visitaEstadoChipMap[estadoId as VisitaEstado] || {
        textColor: 'white',
        backgroundColor: 'gray',
        text: 'Desconocido'
    };

    return (
        <Chip
            label={chip.text}
            sx={{
                backgroundColor: chip.backgroundColor,
                color: chip.textColor,
                fontWeight: 600,
                fontSize: '0.75rem'
            }}
        />
    );
};

export { VisitaEstadoChip };
