import { Box, Grid, Button, TextareaAutosize, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCreateVisita from './hooks/useCreateVisita';
import SectionFormBox from '../../../components/SectionFormBox/SectionFormBox';
import AutocompleteComponent from '../../../components/layout/Autocomplete';
import { useGetTiposVisitasQuery } from '../../../services/visitasApi';
import { useGetUsersQuery } from '../../../services/usersApi';
import { DatePicker } from '@mui/x-date-pickers';
import EstablecimientosSelector from '../components/EstablecimientosSelector';
import dayjs from 'dayjs';

function CreateVisita() {
    const { t } = useTranslation('visitas');
    const {
        step,
        setStep,
        formData,
        handleChange,
        contracts,
        provinces,
        cities,
        organisms,
        handleContractSelection,
        establishments,
        handleSelectEstablishment,
        handleClearSelection,
        handleSubmit,
        handleCancel,
        isNextDisabled,
        isFetchingContracts
    } = useCreateVisita();

    const { data: tiposVisitas, isLoading: loadingTiposVisitas } = useGetTiposVisitasQuery(null);

    const tiposVisitasOptions = (tiposVisitas?.data || []).map((tipo: any) => ({
        nombre: `${tipo.descripcion}`,
        id: tipo.id,
        value: tipo.id
    }));

    const { data: usersData, isLoading: loadingUsers } = useGetUsersQuery({
        skip: 0,
        take: 1000
    });

    const preventores = (usersData?.data?.data || []).filter((user: any) =>
        user.roles.some((role: any) => role.id === 6)
    );

    const preventoresOptions = preventores.map((user: any) => ({
        nombre: `${user.nombre} ${user.apellido}`,
        id: user.id,
        value: user.id
    }));

    return (
        <Box>
            {step === 1 ? (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '90vh' }}>
                        <SectionFormBox className="1" title={t('newVisit.visitDetails')}>
                            <Grid item xs={12} sm={4}>
                                <AutocompleteComponent
                                    value={formData.idTipoVisita || null}
                                    options={tiposVisitasOptions}
                                    size="small"
                                    name="idTipoVisita"
                                    label={`${t('filters.visitType')} *`}
                                    loading={loadingTiposVisitas}
                                    onChange={(value: any) =>
                                        handleChange({ target: { name: 'idTipoVisita', value } })
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <AutocompleteComponent
                                    value={formData.idPreventor || null}
                                    options={preventoresOptions}
                                    size="small"
                                    name="idPreventor"
                                    label={`${t('filters.preventor')} *`}
                                    loading={loadingUsers}
                                    onChange={(value: any) =>
                                        handleChange({ target: { name: 'idPreventor', value } })
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <DatePicker
                                    label={`${t('filters.deadlineDate')} *`}
                                    value={formData.fechaLimite || null}
                                    onChange={(newDate) =>
                                        handleChange({
                                            target: { name: 'fechaLimite', value: newDate }
                                        })
                                    }
                                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                    format="DD/MM/YYYY"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextareaAutosize
                                    minRows={4}
                                    name="observaciones"
                                    value={formData.observaciones}
                                    onChange={handleChange}
                                    placeholder={t('filters.observations') ?? ''}
                                    style={{ width: '100%', padding: '8px' }}
                                />
                            </Grid>
                        </SectionFormBox>

                        <SectionFormBox className="2" title={t('newVisit.establishmentInfo')}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={12}>
                                    <AutocompleteComponent
                                        value={formData.nroContrato || null}
                                        options={contracts.map((contract) => ({
                                            nombre: `${contract.nroContrato} - ${contract.nombre}`,
                                            id: contract.id,
                                            value: contract.nroContrato
                                        }))}
                                        disabled={isFetchingContracts}
                                        loading={isFetchingContracts}
                                        size="small"
                                        name="nroContrato"
                                        label={`${t('filters.contract')} *`}
                                        onChange={(value: any) => handleContractSelection(value)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={12}>
                                    <AutocompleteComponent
                                        value={formData.idOrganismo || null}
                                        options={formData?.nroContrato ? organisms : [] }
                                        disabled={!formData?.nroContrato?.id}
                                        size="small"
                                        name="idOrganismo"
                                        label={`${t('filters.organism')} *`}
                                        onChange={(value: any) =>
                                            handleChange({ target: { name: 'idOrganismo', value } })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <AutocompleteComponent
                                        value={formData.provincia || null}
                                        options={provinces}
                                        size="small"
                                        name="idProvincia"
                                        label={t('filters.province')}
                                        onChange={(value: any) =>
                                            handleChange({ target: { name: 'idProvincia', value } })
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <AutocompleteComponent
                                        value={formData.ciudad || null}
                                        options={cities}
                                        size="small"
                                        name="ciudad"
                                        label={t('filters.city')}
                                        disabled={!formData.idProvincia}
                                        onChange={(value: any) =>
                                            handleChange({ target: { name: 'ciudad', value } })
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <AutocompleteComponent
                                        value={formData.codigoPostal || null}
                                        options={[]}
                                        size="small"
                                        name="codigoPostal"
                                        label={t('filters.postalCode')}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </SectionFormBox>

                        <Box sx={{ mt: 'auto', pb: 3 }}>
                            <Grid container justifyContent="space-between">
                                <Button variant="outlined" onClick={handleCancel}>
                                    {t('buttons.cancel')}
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={isNextDisabled}
                                    onClick={() => setStep(2)}
                                >
                                    {t('buttons.next')}
                                </Button>
                            </Grid>
                        </Box>
                    </Box>
                </>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '90vh' }}>
                    <SectionFormBox title={t('newVisit.summary')} className={'2'}>
                        <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6">
                                    <strong>{t('filters.visitType')}</strong>{' '}
                                </Typography>
                                <Typography variant="body2">
                                    {formData.idTipoVisita?.nombre || '-'}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6">
                                    <strong>{t('filters.preventor')}</strong>{' '}
                                </Typography>
                                <Typography variant="body2">
                                    {formData.idPreventor?.nombre || '-'}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6">
                                    <strong>{t('filters.deadlineDate')}</strong>{' '}
                                </Typography>
                                <Typography variant="body2">
                                    {formData.fechaLimite
                                        ? dayjs(formData.fechaLimite).format('DD/MM/YYYY')
                                        : '-'}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6">
                                    <strong>{t('filters.contract')}</strong>{' '}
                                </Typography>
                                <Typography variant="body2">
                                    {formData.nroContrato ? ` ${formData.nroContrato.nombre}` : '-'}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6">
                                    <strong>{t('filters.organism')}</strong>{' '}
                                </Typography>
                                <Typography variant="body2">
                                    {formData.idOrganismo?.nombre || '-'}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    <strong>{t('filters.observations')}</strong>{' '}
                                </Typography>
                                <Typography variant="body2">
                                    {formData.observaciones || '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </SectionFormBox>

                    <EstablecimientosSelector
                        establishments={establishments}
                        selectedEstablishments={formData.idEstablecimientos}
                        handleSelectEstablishment={handleSelectEstablishment}
                        handleClearSelection={handleClearSelection}
                    />
                    <Box sx={{ mt: 5, pb: 3 }}>
                        <Grid container justifyContent="space-between">
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    handleClearSelection();
                                    setStep(1);
                                }}
                            >
                                {t('buttons.goBack')}
                            </Button>
                            <Button
                                variant="contained"
                                disabled={formData.idEstablecimientos.length === 0}
                                onClick={handleSubmit}
                            >
                                {t('buttons.finish')}
                            </Button>
                        </Grid>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default CreateVisita;
