import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { hideAlert, showAlert } from '../../../features/slices/applicationSlice';
import { usePatchVisitaEstadoMutation } from '../../../services/visitasApi';
import { Visita } from '../consts/visitasTypes';
import { getDateTime } from '../../../utils/common';
import { DrawerContainer } from '../../../components/Drawer/DrawerContainer';
import { DrawerHeader } from '../../../components/Drawer/DrawerHeader';
import { enqueueSnackbar } from 'notistack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAppSelector } from '../../../hooks/reduxHooks';
interface DrawerVisitasProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    actionType: 'Detalle' | 'Cancelar' | 'Rechazar' | null;
    visita: Visita | null;
}

const DrawerVisitas: React.FC<DrawerVisitasProps> = ({ open, setOpen, actionType, visita }) => {
    const { t } = useTranslation('visitas');
    const dispatch = useAppDispatch();
    const [observacion, setObservacion] = useState('');
    const [patchVisitaEstado, { isLoading }] = usePatchVisitaEstadoMutation();
    const user = useAppSelector((state) => state.auth.user);
    const handleConfirm = async () => {
        if (!visita) return;

        if (!observacion.trim()) {
            enqueueSnackbar(t('drawer.warningReason', { action: actionType?.toLowerCase() }), {
                variant: 'warning'
            });
            return;
        }

        const estado = actionType === 'Cancelar' ? 4 : 5;
        const fechaHora = getDateTime(new Date().toISOString());
        const observacionPrevia = visita.observacion ? `${visita.observacion}\n` : '';

        const nombreUsuario = user?.nombre || '';
        const cuilUsuario = user?.cuil || '';

        const observacionFinal = `${observacionPrevia}${fechaHora} - ${
            actionType === 'Cancelar' ? 'Cancelado' : 'Rechazado'
        } por ${nombreUsuario} (CUIL: ${cuilUsuario}) - Motivo: ${observacion}`;

        try {
            await patchVisitaEstado({
                idVisita: visita.id,
                estado,
                observacion: observacionFinal
            }).unwrap();

            enqueueSnackbar(
                t(actionType === 'Cancelar' ? 'drawer.successCancel' : 'drawer.successReject'),
                {
                    variant: 'success'
                }
            );
            setOpen(false);
        } catch (error) {
            enqueueSnackbar(t('drawer.errorUpdate'), { variant: 'error' });
        }
    };

    const confirmAction = () => {
        dispatch(
            showAlert({
                title: t(
                    actionType === 'Cancelar' ? 'drawer.confirmCancel' : 'drawer.confirmReject'
                ),
                icon: 'info',
                keepMounted: true,
                confirmText: t('drawer.confirm'),
                cancelText: t('drawer.cancel'),
                cancelAction: () => dispatch(hideAlert()),
                confirmAction: handleConfirm
            })
        );
    };

    return (
        <DrawerContainer open={open} setOpen={setOpen}>
            <DrawerHeader
                onIconClick={() => setOpen(false)}
                text={
                    actionType === 'Detalle'
                        ? t('drawer.title.detail')
                        : actionType === 'Cancelar'
                        ? t('drawer.title.cancel')
                        : t('drawer.title.reject')
                }
            />

            {visita && (
                <Alert
                    severity="info"
                    sx={{
                        backgroundColor: '#E3F2FD',
                        borderLeft: '5px solid #175AB6',
                        padding: 2,
                        fontWeight: 600,
                        mt: 5,
                        mb: 5
                    }}
                    icon={<InfoOutlinedIcon sx={{ color: '#175AB6' }} />}
                >
                    <AlertTitle sx={{ fontWeight: 600 }}>{t('drawer.info.alertTitle')}</AlertTitle>

                    <Typography variant="h6">
                        <strong>{t('drawer.info.contract')}:</strong> {visita.nroContrato}
                    </Typography>
                    <Typography variant="h6">
                        <strong>{t('drawer.info.organism')}:</strong> {visita.organismo}
                    </Typography>
                    <Typography variant="h6">
                        <strong>{t('drawer.info.establishment')}:</strong>{' '}
                        {visita.nombreEstablecimiento}
                    </Typography>
                    <Typography variant="h6">
                        <strong>{t('drawer.info.city')}:</strong> {visita.city}
                    </Typography>
                    <Typography variant="h6">
                        <strong>{t('drawer.info.zipCode')}:</strong> {visita.zipCode}
                    </Typography>
                    <Typography variant="h6">
                        <strong>{t('drawer.info.visitType')}:</strong> {visita.nombreTipoVisita}
                    </Typography>
                    <Typography variant="h6">
                        <strong>{t('drawer.info.deadline')}:</strong> {visita.fechaLimite}
                    </Typography>
                    <Typography variant="h6">
                        <strong>{t('drawer.info.observations')}:</strong>{' '}
                        {visita.observacion || t('drawer.noObservations')}
                    </Typography>
                </Alert>
            )}

            {actionType !== 'Detalle' && (
                <>
                    <TextField
                        label={
                            actionType === 'Cancelar'
                                ? t('drawer.cancelReason')
                                : t('drawer.rejectReason')
                        }
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={observacion}
                        onChange={(e) => setObservacion(e.target.value)}
                    />

                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 16,
                            right: 16,
                            display: 'flex',
                            gap: 2
                        }}
                    >
                        <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
                            {t('drawer.close')}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={confirmAction}
                            disabled={isLoading}
                        >
                            {t(
                                actionType === 'Cancelar'
                                    ? 'drawer.cancelVisit'
                                    : 'drawer.rejectVisit'
                            )}
                        </Button>
                    </Box>
                </>
            )}
        </DrawerContainer>
    );
};

export default DrawerVisitas;
