import { Box, CircularProgress, Grid } from '@mui/material';
import { ReactNode } from 'react';

interface IndicatorCardProps {
    onClick: () => void;
    children: ReactNode;
    value: number;
    detail: string;
    loading?: boolean;
    testID?: string;
}

const IndicatorCard = ({
    children,
    value,
    detail,
    onClick,
    loading = false,
    testID = "indicator-card"
}: IndicatorCardProps) => (
    <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        borderRadius={'8px'}
        padding={2}
        justifyContent={'space-between'}
        height={'225px'}
        sx={{ cursor: 'pointer', backgroundColor: 'white' }}
        onClick={onClick}
        data-testid={testID}
    >
        <Grid container data-testid={`${testID}-container`}>{children}</Grid>
        <Grid data-testid={`${testID}-value`} xs={12} sx={{ fontSize: '64px', fontWeight: '600' }}>
            {loading ? <CircularProgress /> : value !== null ? value : '-'}
        </Grid>
        <Grid data-testid={`${testID}-detail`} xs={12} sx={{ fontWeight: '400', fontSize: '18px' }}>
            {detail || '-'}
        </Grid>
    </Box>
);

export { IndicatorCard };
