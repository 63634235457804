export enum VisitaEstado {
    PENDIENTE = 1,
    REALIZADA = 2,
    FALLIDA = 3,
    CANCELADA = 4,
    RECHAZADO = 5
}

export const VisitStatus: Record<VisitaEstado, string> = {
    [VisitaEstado.PENDIENTE]: 'PENDIENTE',
    [VisitaEstado.REALIZADA]: 'REALIZADA',
    [VisitaEstado.FALLIDA]: 'FALLIDA',
    [VisitaEstado.CANCELADA]: 'CANCELADA',
    [VisitaEstado.RECHAZADO]: 'RECHAZADO'
};

export const visitaEstadoChipMap: Record<
    VisitaEstado,
    { textColor: string; backgroundColor: string; text: string }
> = {
    [VisitaEstado.PENDIENTE]: {
        textColor: '#6B7280',
        backgroundColor: '#F3F4F6',
        text: 'Pendiente'
    },
    [VisitaEstado.REALIZADA]: {
        textColor: '#126015',
        backgroundColor: '#D9EBC5',
        text: 'Realizada'
    },
    [VisitaEstado.FALLIDA]: {
        textColor: '#F44336',
        backgroundColor: '#F9E7E7',
        text: 'Fallida'
    },
    [VisitaEstado.CANCELADA]: {
        textColor: '#F44336',
        backgroundColor: '#F9E7E7',
        text: 'Cancelada'
    },
    [VisitaEstado.RECHAZADO]: {
        textColor: '#F44336',
        backgroundColor: '#F9E7E7',
        text: 'Rechazado'
    }
};
