import _ from 'lodash';
import { Box, Grid, Menu, MenuItem, Chip, Stack, Button } from '@mui/material';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable/DataTable';
import SearchToolbar from '../../components/SearchToolbar/SearchToolbar';
import SectionHeader from '../../components/SectionHeader';
import useGlobalFilters from '../../hooks/useGlobalFilters';
import { useAppSelector } from '../../hooks/reduxHooks';
import VisitasFilters from './components/VisitasFilters';
import useVisitas from './hooks/useVisitas';
import { IndicatorCard } from '../HomePrivado/components/Indicators/IndicatorCard';
import NoTableDataImageBO from '../../assets/image/empty-state-establecimientos-backoffice.png';
import DrawerVisitas from './components/DrawerVisitas';
import { useRoles } from '../../hooks/useRoles';
import { VisitaEstadoChip } from './components/EstadoChip';
import { getAccessValues } from '../../components/layout/RoutesManager/access';
import useUserAccess from '../../hooks/useUserAccess';

function VisitasIndex({ isHome }: { isHome?: boolean }) {
    const { isMobile, isTablet } = useIsMobile();
    const { t } = useTranslation('visitas');
    const { role, isAdminBO, isPreventor } = useRoles();
    const access = useUserAccess();
    const FEATURES = getAccessValues(access);

    const navigate = useNavigate();
    const columnWidths = ['10%', '15%', '10%', '10%', '10%', '10%', '15%', '15%', '5%'];

    const {
        filters,
        setFilters,
        extraFilters,
        handleSubmitSearch,
        handleChangeExtraFilters,
        debounceSearch,
        setExtraFilters,
        clearFilters
    } = useGlobalFilters();

    const {
        selected,
        visitasData,
        indicatorsData,
        isFetchingIndicators,
        openCard,
        anchorEl,
        menuOpen,
        isLoading,
        isFetching,
        handleClickAction,
        handleSelectRow,
        handleCloseMenu,
        drawerOpen,
        drawerAction,
        handleOpenDrawer,
        selectedVisita,
        setDrawerOpen
    } = useVisitas({ filters });

    const columnTitles = (t: (key: string) => string): string[] => {
        const titles = [
            t('table.columnsTitles.nro'),
            t('table.columnsTitles.establishment'),
            t('table.columnsTitles.city'),
            t('table.columnsTitles.postalCode'),
            t('table.columnsTitles.visitType'),
            t('table.columnsTitles.preventor'),
            t('table.columnsTitles.state'),
            t('table.columnsTitles.visitLimitDate'),
            t('table.columnsTitles.visitDate'),
            t('table.columnsTitles.actions')
        ];

        return isPreventor
            ? titles.filter((title) => title !== t('table.columnsTitles.preventor'))
            : titles;
    };

    const headers = [
        { type: 'data', upperLabel: 'idEstablecimiento', align: 'left' },
        { type: 'data', upperLabel: 'nombreEstablecimiento', align: 'left' },
        { type: 'data', upperLabel: 'city', align: 'left' },
        { type: 'data', upperLabel: 'zipCode', align: 'left' },
        { type: 'data', upperLabel: 'nombreTipoVisita', align: 'left' },
        { type: 'data', upperLabel: 'nombrePreventor', align: 'left' },
        {
            type: 'data',
            upperLabel: (item: any) =>
                item?.idEstado ? <VisitaEstadoChip estadoId={item?.idEstado} /> : '-'
        },
        { type: 'data', upperLabel: 'fechaLimite', align: 'left' },
        { type: 'data', upperLabel: 'fechaVisita', align: 'left' },
        { type: 'action', onClick: handleClickAction }
    ].filter((column) => !(isPreventor && column.upperLabel === 'nombrePreventor'));

    return (
        <>
            {isHome && (
                <Grid display={'flex'} gap={'20px'} marginBottom={5}>
                    <IndicatorCard
                        onClick={() => {}}
                        value={indicatorsData?.pendientes}
                        detail={t('indicators.pendingVisits.detail')}
                        loading={isFetchingIndicators}
                    >
                        <Chip
                            label={t('indicators.pendingVisits.label')}
                            sx={{
                                backgroundColor: '#F3F4F6',
                                fontWeight: 600,
                                color: '#6B7280',
                                border: '1px solid #6B7280'
                            }}
                        />
                    </IndicatorCard>
                    <IndicatorCard
                        onClick={() => {}}
                        value={indicatorsData?.realizadas}
                        detail={t('indicators.completedVisits.detail')}
                        loading={isFetchingIndicators}
                    >
                        <Chip
                            label={t('indicators.completedVisits.label')}
                            sx={{
                                backgroundColor: '#d9ebc58f',
                                fontWeight: 600,
                                color: '#4CAF50',
                                border: '1px solid #4CAF50'
                            }}
                        />
                    </IndicatorCard>
                </Grid>
            )}

            {!isHome && (
                <SectionHeader>
                    <SectionHeader.Title>{t('header.title')}</SectionHeader.Title>
                    {FEATURES.ALTA_DE_VISITAS.hasAccess && isAdminBO && (
                        <SectionHeader.IconHeader
                            text={t('header.add')}
                            onClick={() => navigate('crear')}
                        />
                    )}
                </SectionHeader>
            )}
            {!isHome && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SearchToolbar
                            onChange={debounceSearch}
                            inputSearchName="visita"
                            label={t('filters.searchTerm')}
                            showSearchInput={false}
                            onClick={handleSubmitSearch}
                            clearFilters={clearFilters}
                            showMoreFiltersButton={false}
                        >
                            <VisitasFilters
                                extraFilters={extraFilters}
                                setExtraFilters={setExtraFilters}
                                handleChangeExtraFilters={handleChangeExtraFilters}
                            />
                        </SearchToolbar>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={3}>
                {openCard && (isMobile || isTablet) ? null : (
                    <Grid item xs={openCard ? 6 : 12} lg={openCard ? 8 : 12}>
                        {visitasData?.data?.count === 0 && !isFetching ? (
                            <Box
                                my={1}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        '& h4': {
                                            fontSize: '18px'
                                        },
                                        '& p': {
                                            maxWidth: '360px',
                                            textAlign: 'center',
                                            margin: 0,
                                            fontSize: '16px'
                                        }
                                    }}
                                >
                                    <img src={NoTableDataImageBO} alt="" />
                                    <h4>{t('table.noData.title')}</h4>
                                    {isHome && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ mt: 2 }}
                                            onClick={() => navigate('../backoffice/visitas')}
                                        >
                                            {t('table.noData.btnRedirection')}
                                        </Button>
                                    )}
                                </Stack>
                            </Box>
                        ) : (
                            <DataTable
                                columnTitles={columnTitles(t)}
                                columnWidths={columnWidths}
                                menuOpen={menuOpen}
                                headers={headers}
                                onSelectRow={handleSelectRow}
                                isLoading={isLoading}
                                items={visitasData}
                                filters={filters}
                                setFilters={setFilters}
                                isFetching={isFetching}
                            />
                        )}
                    </Grid>
                )}
            </Grid>

            <Menu id="basic-menu" anchorEl={anchorEl} open={menuOpen} onClose={handleCloseMenu}>
                {FEATURES.GESTION_DE_VISITAS.hasAccess && (
                    <MenuItem
                        onClick={() => {
                            handleCloseMenu();
                            handleOpenDrawer('Detalle', selected!);
                        }}
                    >
                        {t('table.actions.seeDetail')}
                    </MenuItem>
                )}

                {FEATURES.GESTION_DE_VISITAS.hasAccess &&
                    isAdminBO &&
                    selected?.idEstado !== 3 &&
                    selected?.idEstado !== 4 &&
                    selected?.idEstado !== 5 && (
                        <MenuItem
                            onClick={() => {
                                handleCloseMenu();
                                handleOpenDrawer('Cancelar', selected!);
                            }}
                        >
                            {t('drawer.cancelVisit')}
                        </MenuItem>
                    )}

                {FEATURES.GESTION_DE_VISITAS.hasAccess &&
                    isPreventor &&
                    selected?.idEstado !== 3 &&
                    selected?.idEstado !== 4 &&
                    selected?.idEstado !== 5 && (
                        <MenuItem
                            onClick={() => {
                                handleCloseMenu();
                                handleOpenDrawer('Rechazar', selected!);
                            }}
                        >
                            {t('drawer.rejectVisit')}
                        </MenuItem>
                    )}
            </Menu>

            <DrawerVisitas
                open={drawerOpen}
                setOpen={setDrawerOpen}
                actionType={drawerAction}
                visita={selectedVisita}
            />
        </>
    );
}

export default VisitasIndex;
